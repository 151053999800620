<template>
    <div class="bloc__openVideo" @wheel.prevent="disabled_wheel" @mousewheel.prevent="disabled_wheel" :class="{'animPaint__fadeOut': brushAnim, 'videoHidden':watchVideo}">
        <div class="bloc__openVideo-ctn">
            <vue-player
                video
                :sources="videoSources"
                :poster="'videos/harmony_master.jpg'" 
                @ended="onEnd()"       
            />
            <div class="btn-pos">
                <button id="btn-play" type="button" class="btn btn-OpenVideo btn__play" @click="playVideo" aria-label="play video"></button>
                <button id="btn-skip" type="button" class="btn btn-OpenVideo" @click="skipVideo" aria-label="skip video">{{$t('skip')}}</button>
            </div>
        </div>

    </div>
</template>

<script>
    export default{
        name: 'OpeningVideo',
        props: {
            msg: String,
        },
         data() {
            return{
                watchVideo: false,
                brushAnim: false,
                videoSources: {
                    "video/mp4":
                    "videos/harmony_master.mp4",
                    "video/webm":
                    "videos/harmony_master.webm"
                }
            }
        },

        methods:{
            playVideo(){
                var video = document.querySelector(".bloc__openVideo-ctn video")
                document.querySelector(".btn__play").style.opacity = 0;
                video.play()
                
                setTimeout(() => {
                    document.querySelector(".btn__play").style.display = "none";
                }, 500);
                //Add video to cookies
                // this.createCookie("watchVideo", 1, 3);
           
            },
            skipVideo(){
                var videoCtnBloc = document.querySelector(".bloc__openVideo")
                setTimeout(() => {
                   this.brushAnim = true;
                   document.querySelector('.logo-bandeau-white').classList.add('slide-in-top')
                }, 300);
                setTimeout(() => {
                    videoCtnBloc.classList.add('hideBlock');
                    document.querySelector('.page-hp').click();
                    document.querySelector(".bloc__openVideo-ctn video").pause()
                }, 1500);
                
            },
            onEnd(){
                var videoCtnBloc = document.querySelector(".bloc__openVideo")
                setTimeout(() => {
                   this.brushAnim = true;
                   document.querySelector('.logo-bandeau-white').classList.add('slide-in-top')
                }, 300);
                setTimeout(() => {
                    videoCtnBloc.classList.add('hideBlock');
                    document.querySelector('.page-hp').click();
                    document.querySelector(".bloc__openVideo-ctn video").pause()
                }, 1500);
                
            },
            /******    CREATE COOKIES     ******/
            createCookie(name, value, days) {
                var expires;
                if (days) {
                    var date = new Date();
                    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                    expires = "; expires=" + date.toGMTString();
                }
                else {
                    expires = "";
                }
                document.cookie = name + "=" + value + expires + "; path=/";
            },
            /******    GET COOKIES VALUE    ******/
            getCookie(c_name) {
                if (document.cookie.length > 0) {
                   let c_start = document.cookie.indexOf(c_name + "=");
                    if (c_start != -1) {
                        c_start = c_start + c_name.length + 1;
                       let c_end = document.cookie.indexOf(";", c_start);
                        if (c_end == -1) {
                            c_end = document.cookie.length;
                        }
                        return unescape(document.cookie.substring(c_start, c_end));
                    }
                }
                return "";
            },
            disabled_wheel(e){
                e.stopPropagation();
                return false;
            }
        },
        mounted(){
            var video = document.querySelector(".media")
            video.addEventListener("ended", this.skipVideo)
        }
    }
</script>